.signature-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
}

.signature-canvas {
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
}

.signature-controls {
  display: flex;
  gap: 10px;
}

button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}